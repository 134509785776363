import React from "react";

function Home()
{
    return(
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <h5 className="mt-2">Welcome to React Practical 2</h5>
                    </div>
                </div>
            </div>
            
        </React.Fragment>
    );
}

export default Home;